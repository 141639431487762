import { defineStore } from 'pinia'
// Types.
//
export interface Overlay {
  level: number
}
export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    menuPopover: false,
    menu: false,
    overlays: [],
    overlayState: false,
    perPage: 25,
    filters: true,
    recentlyViewed: false,
    darkMode: false,
    datePickerOpen: false,
  }),
  actions: {
    toggleMenu() {
      this.menu = !this.menu
    },
    toggleMenuPopover() {
      this.menuPopover = !this.menuPopover
    },
    toggleOverlayState() {
      this.overlayState = !this.overlayState
    },
    clearOverlayState() {
      this.overlayState = false
    },
    closeMenu() {
      this.menu = false
    },
    addOverlay(overlay: Overlay) {
      // @ts-ignore
      this.overlays.push(overlay)
    },
    removeOverlay(overlay: Overlay) {
      if (this.overlays.length > 0) {
        const idx = this.overlays.findIndex(
          (o: Overlay) => o.level === overlay.level,
        )
        this.overlays.splice(idx, 1)
      }
      this.recentlyViewed = false
    },
    clearOverlays() {
      this.overlays = []
    },
    toggleRecentlyViewed() {
      this.recentlyViewed = !this.recentlyViewed
    },
    toggleDarkMode(state?: boolean) {
      state ? (this.darkMode = state) : (this.darkMode = !this.darkMode)
    },
    toggleDatePicker(state?: boolean) {
      state
        ? (this.datePickerOpen = state)
        : (this.datePickerOpen = !this.datePickerOpen)
    },
  },
  persist: {
    storage: localStorage,
    paths: ['menu', 'filters', 'perPage', 'darkMode'],
  },
})
