import type { Organization } from '~/apollo/types'
export const useAuthStore = defineStore('auth', {
  state() {
    return {
      user: null as UserInfo | null,
      token: null as string | null,
      orgId: null as string | null,
      session: null as string | null,
    }
  },

  actions: {
    async login(username: string, password: string) {
      const r: LoginResponse = await $fetch('/login', {
        baseURL: useRuntimeConfig().public.apiHost,
        body: { username, password },
        method: 'POST',
      })
      // Set token.
      //
      this.token = r.token
      // Add expiry to login date.
      //
      const plusHours = function plusHours(d: any, hrs: any) {
        const hrsToAdd = hrs * 60 * 60 * 1000
        d.setTime(d.getTime() + hrsToAdd)
        return d
      }
      // Set the cookie, so we can use the token in SSR.
      //
      const today = new Date()
      const pHrs = plusHours(today, 12)
      const expires = new Date(pHrs)
      // Calculate maxAge based on the expiration date.
      //
      const maxAge = Math.floor((expires.getTime() - Date.now()) / 1000)
      // Check session.
      //
      if (!this.session) {
        this.session = expires.toString()
      }
      // Set cookie.
      //
      document.cookie = `rezkit_token=${r.token}; Max-Age=${maxAge}; path=/; domain=${window.location.hostname}; SameSite=Strict;`
    },
    switchOrganization(org: Organization) {
      if (this.user) {
        this.user = { ...this.user, organization: org }
        this.orgId = org.id
      }
    },
  },
  persist: true,
})

interface LoginResponse {
  token: string
}

interface UserInfo {
  id: string
  name: string
  email: string
  organization: Organization
}
