// Variables.
//
const cookie = useCookie('rezkit_token', {
  readonly: true,
})

async function clearSession(type?: string) {
  useAuthStore().user = null
  useAuthStore().token = null
  useAuthStore().session = type === 'expired' ? '' : null
  useGlobalStore().menuPopover = false
  useGlobalStore().removeOverlay({ level: 1 })
  await useApollo().onLogout()
  localStorage.clear()
  sessionStorage.clear()
  document.cookie =
    'rezkit_token=;domain=' + window.location.hostname + ';path=/'
}

async function checkSession() {
  const authStore = useAuthStore()
  const sessionDate =
    authStore.session && authStore.session.length > 0
      ? new Date(authStore.session)
      : null
  const todayDate = new Date()
  if (sessionDate) {
    const d1 = sessionDate.getTime()
    const d2 = todayDate.getTime()
    if (d1 <= d2) {
      await clearSession('expired')
    } else {
      return 'OK'
    }
  } else if (cookie.value && cookie.value?.length > 0 && !authStore.session) {
    // Clear when a cookie exists, but the session doesn't.
    //
    await clearSession()
  } else {
    return 'OK'
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', async (error, instance, info) => {
    console.log(error)
    console.log(instance)
    console.log(info)
    // Session check before redirecting to error page.
    //
    const session = await checkSession()
    if (session === 'OK') {
      navigateTo('/error')
    }
  })

  nuxtApp.hook('app:beforeMount', async () => {
    // Ping API.
    //
    const apiHealth =
      process.env.NODE_ENV === 'development'
        ? 'https://api.staging.rezkit.app/_healthz'
        : null
    if (apiHealth) {
      const resp = await $fetch(apiHealth).catch((e) => {
        console.log(e)
        clearSession()
      })
      if (resp && resp === 'OK') {
        console.log('RezKit OK')
      }
    }
    // Check for RezKit cookie.
    //
    if (!cookie.value || (cookie.value && cookie.value.length === 0)) {
      await clearSession()
    }
  })
  nuxtApp.hook('app:mounted', async () => {
    await checkSession()
  })
})
