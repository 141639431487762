import { default as _91id_935WRrYTZJqdMeta } from "/vercel/path1/pages/bookings/[id].vue?macro=true";
import { default as createXZ3PHm2NNgMeta } from "/vercel/path1/pages/bookings/create.vue?macro=true";
import { default as indexah6XI90FqFMeta } from "/vercel/path1/pages/bookings/index.vue?macro=true";
import { default as confirm_45passwordSJRC9U6BInMeta } from "/vercel/path1/pages/confirm-password.vue?macro=true";
import { default as _91id_93m2Mngspn1aMeta } from "/vercel/path1/pages/customers/[id].vue?macro=true";
import { default as createkzQJUBx0oxMeta } from "/vercel/path1/pages/customers/create.vue?macro=true";
import { default as indexwZqqNYl6t4Meta } from "/vercel/path1/pages/customers/index.vue?macro=true";
import { default as _91id_932zISmH8sIuMeta } from "/vercel/path1/pages/discounts/[id].vue?macro=true";
import { default as create3A4vAJPfFCMeta } from "/vercel/path1/pages/discounts/create.vue?macro=true";
import { default as indexhSo8llzGUBMeta } from "/vercel/path1/pages/discounts/index.vue?macro=true";
import { default as indexzU4zEycnIaMeta } from "/vercel/path1/pages/error/index.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as loginlsVM2qGgsfMeta } from "/vercel/path1/pages/login.vue?macro=true";
import { default as authjT8voUsrwuMeta } from "/vercel/path1/pages/oauth/auth.vue?macro=true";
import { default as indexSNtgXOBMAbMeta } from "/vercel/path1/pages/organizations/index.vue?macro=true";
import { default as userswhT7lrZKVTMeta } from "/vercel/path1/pages/organizations/users.vue?macro=true";
import { default as _91id_932suuDHbdoEMeta } from "/vercel/path1/pages/products/[id].vue?macro=true";
import { default as createwEX00bMhh5Meta } from "/vercel/path1/pages/products/create.vue?macro=true";
import { default as indexrc42zYLeoHMeta } from "/vercel/path1/pages/products/index.vue?macro=true";
import { default as register3VIXDPK4aGMeta } from "/vercel/path1/pages/register.vue?macro=true";
import { default as reset_45passwordRY4Jz2aDdWMeta } from "/vercel/path1/pages/reset-password.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as indexWv1A9vmlQeMeta } from "/vercel/path1/pages/settings/tags/index.vue?macro=true";
export default [
  {
    name: "bookings-id",
    path: "/bookings/:id()",
    component: () => import("/vercel/path1/pages/bookings/[id].vue").then(m => m.default || m)
  },
  {
    name: "bookings-create",
    path: "/bookings/create",
    component: () => import("/vercel/path1/pages/bookings/create.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/vercel/path1/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm-password",
    path: "/confirm-password",
    meta: confirm_45passwordSJRC9U6BInMeta || {},
    component: () => import("/vercel/path1/pages/confirm-password.vue").then(m => m.default || m)
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/vercel/path1/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers-create",
    path: "/customers/create",
    component: () => import("/vercel/path1/pages/customers/create.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/vercel/path1/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "discounts-id",
    path: "/discounts/:id()",
    component: () => import("/vercel/path1/pages/discounts/[id].vue").then(m => m.default || m)
  },
  {
    name: "discounts-create",
    path: "/discounts/create",
    component: () => import("/vercel/path1/pages/discounts/create.vue").then(m => m.default || m)
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/vercel/path1/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/vercel/path1/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginlsVM2qGgsfMeta || {},
    component: () => import("/vercel/path1/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-auth",
    path: "/oauth/auth",
    component: () => import("/vercel/path1/pages/oauth/auth.vue").then(m => m.default || m)
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/vercel/path1/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-users",
    path: "/organizations/users",
    component: () => import("/vercel/path1/pages/organizations/users.vue").then(m => m.default || m)
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/vercel/path1/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-create",
    path: "/products/create",
    component: () => import("/vercel/path1/pages/products/create.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path1/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: register3VIXDPK4aGMeta || {},
    component: () => import("/vercel/path1/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordRY4Jz2aDdWMeta || {},
    component: () => import("/vercel/path1/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path1/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-tags",
    path: "/settings/tags",
    component: () => import("/vercel/path1/pages/settings/tags/index.vue").then(m => m.default || m)
  }
]