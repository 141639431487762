export default defineNuxtRouteMiddleware((to, from) => {
  // Get the token cookie
  //
  const cookie = useCookie('rezkit_token', {
    readonly: true,
  })
  const runClear = function runClear() {
    useAuthStore().user = null
    useAuthStore().token = null
    useAuthStore().session = null
    useGlobalStore().menuPopover = false
    useGlobalStore().removeOverlay({ level: 1 })
  }
  const noCookieCheck =
    !cookie.value &&
    !to.meta.allowUnauthenticatedOnly &&
    !to.query.invite &&
    !to.query.client_id &&
    !to.query.redirect_url &&
    to.path !== '/login'
  const withCookieCheck =
    cookie.value &&
    cookie.value.length === 0 &&
    !to.meta.allowUnauthenticatedOnly &&
    !to.query.invite &&
    !to.query.client_id &&
    !to.query.redirect_url &&
    to.path !== '/login'

  // If there's no cookie and this isn't an unauthenticated-only route
  // redirect to login.
  //
  if (noCookieCheck) {
    runClear()
    return navigateTo('/login')
  } else if (withCookieCheck) {
    runClear()
    return navigateTo('/login')
  }

  // If this is an unauthenticated-only route, divert authenticated users.
  //
  if (cookie.value && to.meta.allowUnauthenticatedOnly) {
    return navigateTo(to.meta.navigateAuthenticatedTo || '/')
  }

  return
})
