import { defineStore } from 'pinia'

export interface Alert {
  id: number
  message: string
  timeout: number
  type?: string
}

export const useAlertStore = defineStore({
  id: 'alerts',
  state: () => ({
    alerts: [],
  }),
  actions: {
    setAlert({ alert, extra }: { alert: Alert; extra?: string }) {
      if (extra && !alert.message.includes(extra)) {
        alert.message = alert.message + extra
      }
      // @ts-ignore
      this.alerts!.push(alert)
      setTimeout(() => {
        this.dismissAlert(alert)
      }, alert.timeout)
    },
    dismissAlert(alert: Alert) {
      const idx = this.alerts!.findIndex((a: Alert) => a.id === alert.id)
      this.alerts!.splice(idx, 1)
    },
  },
})
