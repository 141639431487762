import { GrowthBook } from '@growthbook/growthbook'

export default defineNuxtPlugin(async (): Promise<any> => {
  if (import.meta.browser) {
    const {
      public: { growthbook },
    } = useRuntimeConfig()
    const gb = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: growthbook.key,
    })

    inject('gb', growthbook)
    inject('haveGrowthBook', () => true)

    await gb.loadFeatures()
    const userId = useAuthStore().user?.id
    await gb.setAttributes({
      id: userId,
      deviceId: '',
      organization: useAuthStore().user?.organization?.id,
      loggedIn: userId !== null,
      employee: false,
      url: window.location.href,
    })

    return {
      provide: { growthbook: gb },
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('Not initializing GrowhBook in non-browser environment')
    inject('haveGrowthBook', () => false)
  }
})
