<script setup lang="ts">
// Store mount.
//
useGlobalStore()
useAlertStore()
useAuthStore()
</script>

<template>
  <div>
    <Head>
      <Title>RezKit</Title>
    </Head>
    <NuxtLoadingIndicator color="#84288E" :throttle="0" :duration="3000" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style lang="scss"></style>
